import React, { useState } from "react";

//import styles
import s from "./breadcrumb.module.css";

//import svg icon
import { CaretDownIcon } from "../../svgicons";
import { currencyFormat } from "../../../actions/formatter";
import { useSelector } from "react-redux";
import { getTaxAmmount, getTaxPercent } from "../../../actions/commonFunctions";

export const NavContent = () => {
  const cartData = useSelector((state) => state.cart);
  const taxrate = useSelector((state) => state.taxrate);

  const [detail, setDetail] = useState(false);

  const { ammount } = cartData || {};
  const taxAmmount =
    cartData && taxrate?.combined_rate
      ? getTaxAmmount(taxrate?.combined_rate, cartData.ammount)
      : 0;
  const productAmmount = currencyFormat(cartData?.pricingStructure?.price ?? 0);
  const totalAmmount = currencyFormat(cartData ? taxAmmount + ammount : 0);

  const toggleDetails = () => {
    setDetail((d) => !d);
  };

  const filteredAdditionalItems = cartData?.additionalFee.filter(
    (p) => p?.quantity > 0
  );

  return (
    <>
      <div className="cart">
        <div className="cart-header">
          <div className="cart-header-title">Total</div>
        </div>
        <div className="cart-body">
          <div className="prodcut-title">{cartData?.product?.name}</div>

          <ul className={s.ProductItemList}>
            <li>
              <span>Product delivery & pickup</span>
              <span>{productAmmount}</span>
            </li>
            {filteredAdditionalItems?.map((v, i) => (
              <li key={i}>
                <span>
                  {v?.name} x {v?.quantity}
                </span>
                <span>
                  {
                    v?.charge?.type === 'FIXED' ?
                        <>
                          ${v?.quantity * v?.charge?.value}
                        </> :
                        <>
                          ${v?.quantity * ((cartData?.pricingStructure?.price * v?.charge?.value)/100 )}
                        </>
                  }
                </span>
              </li>
            ))}
            <li>
              <span>
                <button
                  onClick={toggleDetails}
                  className={`btn px-0 ${s.InfoBtn}`}
                >
                  Taxes & Fees{" "}
                  <CaretDownIcon
                    style={{ transform: detail && "scaleY(-1)" }}
                    className={s.Icon}
                  />
                </button>
              </span>
              <span>{currencyFormat(taxAmmount)}</span>
            </li>
            {detail && (
              <li>
                <span>
                  Sales Tax (
                  {taxrate?.combined_rate
                    ? getTaxPercent(taxrate?.combined_rate)
                    : 0}
                  %)
                </span>
                <span>{currencyFormat(taxAmmount)}</span>
              </li>
            )}
          </ul>
          <div className={s.ProductSummaryFooter}>
            <span className={s.ProductSummaryLabel}>
              Total Due <br />
              Upon Delivery{" "}
            </span>
            <span className={s.ProductSummaryAmount}>{totalAmmount}</span>
          </div>
        </div>
        <div className="cart-footer">
          <div className="cart-note">
            *Pricing includes delivery, removal and one week of time.
          </div>
        </div>
      </div>
    </>
  );
};
