import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

//import styles
import s from "./Complete.module.css";

//import components
import Thumb from "../../../assets/images/product-thumb.svg";

//import functions
import { breadCrumbChange } from "../../../redux/reducers/appreducer";
import { getCartLocalStorage } from "../../../actions/localstorage";
import {
  formatDateSlash,
  timeFormat,
  shortDateFormat,
} from "../../../actions/formatter";
import { ThanksScreenCard } from "../../common";
import { useNavigate } from "react-router-dom";
import { clearLocalStorage } from "../../../actions/commonFunctions";

const Index = () => {
  const {
    orderData,
    product,
    serviceAddress: {
      deliveryDate,
      pickupDate,
      preferredTimeRange,
      zipCode,
      state,
      city,
      address,
    },
  } = getCartLocalStorage() || { serviceAddress: {} };
  const cart = getCartLocalStorage();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(breadCrumbChange(4));
  }, []);

  const redirection = localStorage.getItem('redirection');

  const goToHome = () => {
    if(redirection) {
      window.location.replace(redirection)
    } else {
      navigate("/");
    }
    clearLocalStorage();
  };

  const companyData = JSON.parse(sessionStorage.getItem('companyData'));

  console.log("SDF", cart?.orderData);

  return (
    <>
      <div className={s.OrderdetailsMainContainer}>
        <div>
          <div className={s.OrderDetailsSection}>
            <div className={`container ${s.ContainerMain}`}>
              <div className={s.OrderDetailsWrapper}>
                <div className={s.OrderDetailsLogo}>
                  <img
                    className={s.LogoImage}
                    alt="logo"
                    height={50}
                    src={companyData ? companyData?.logoUrl : "./assets/logo.png"}
                  />
                </div>
                <div className={s.OrderDetailsHeader}>
                  <h1 className={s.Title}>
                    Thank you for your order, <span>#{orderData?.orderid}</span>
                  </h1>
                  <p className={s.SubTitle}>
                    Below you will find the details of your order.
                  </p>
                </div>

                <div className={s.OrderDetails}>
                  <div className={s.OrderDetailsInfo}>
                    <div className={s.OrderDetailsThumb}>
                      <img src={Thumb} alt="" />
                    </div>
                    <div className={s.ProductTitle}>{product?.name}</div>
                  </div>

                  <div className={s.OrderDetailsGrid}>
                    <ThanksScreenCard
                      first={"Delivery Date"}
                      second={shortDateFormat(deliveryDate)}
                      third={
                        preferredTimeRange &&
                        `Preferred Time: ${preferredTimeRange.replaceAll(
                          " ",
                          ""
                        )}`
                      }
                    />
                    <ThanksScreenCard
                      first={"Service Address"}
                      second={address}
                      third={`${city}, ${state} ${zipCode}`}
                      // third={"New York, NY 10001"}
                    />
                    {pickupDate && (
                      <ThanksScreenCard
                        first={"Pickup Date"}
                        second={shortDateFormat(pickupDate)}
                        third={
                          preferredTimeRange &&
                          `Preferred Time: ${preferredTimeRange.replaceAll(
                            " ",
                            ""
                          )}`
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
              <ul className="step-progressbar">
                <li className="active">
                  <div className="step-progressbar-inner">
                    <div className="step-progress-label">Order Placed</div>
                    <date className="step-progress-order-date">
                      {formatDateSlash(orderData?.ordereddate)} at{" "}
                      {timeFormat(orderData?.time ?? new Date())}
                    </date>
                  </div>
                </li>
                <li>
                  <div className="step-progressbar-inner">
                    <div className="step-progress-label">Order Confirmed</div>
                    {/* <date className="step-progress-order-date">03/09/23 at 06:16 PM</date> */}
                  </div>
                </li>
                <li>
                  <div className="step-progressbar-inner">
                    <div className="step-progress-label">Delivered</div>
                    {/* <date className="step-progress-order-date">03/09/23 at 06:16 PM</date> */}
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="w-100 py-4 text-center">
            <button onClick={goToHome} className="btn btn-primary px-4">
              Return Home
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default Index;
